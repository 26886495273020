.single--page .text--oben {
    font-size: 40px;
    margin-bottom: 0;
}
.single--page .text--unten {
    font-size: 80px;
    margin:0;
    line-height: .8em;
}
.single--page .text--unten.text--kleiner {
    font-size: 60px;
}
.single--page .text--unten.text--kleinster {
    font-size: 50px;
}
.single--page .text--unten.text--mikro {
    font-size: 40px;
}
.single--page .img--block img {
    display: block;
    margin: 0 auto;
}
.single--page img.bild--spenden {
    margin-top: 70px;
}
.single--page div.spenden--button {
    background-color: #FECC33;
    font-family: 'Oswald', sans-serif;
    padding: 15px;
    color: black;
    margin: 90px auto 0 auto;
    width: 280px;
}
.single--page a {
    text-decoration: none;
}