h1.header--title {
    margin:0;
    padding: 12px 0 0 15px;
    float:left;
}
h1.font--size-FR, h1.font--size-IT {
    font-size: 17px;
    padding-top: 17px;
}
h1.font--size-DE.font--big-title {
    font-size: 34px;
    padding-top: 5px;
}
h1.font--size-FR.font--big-title, h1.font--size-IT.font--big-title {
    font-size: 20px;
    padding-top: 17px;
}
h1.font--size-EN.font--big-title {
    font-size: 34px;
    padding: 5px 0 0 25px;
}