.lang--menu {
    padding-top: 10px;
    position: absolute;
    right: 15px;
}
.lang--menu button {
    font-size: 20px;
    color: #C74755;
    font-weight: 600;
}
.lang--menu .polygon {
    width: 15px;
   position: absolute;
   top: 29px;
   right: 0;
}