.wrapper {
    width: 100vw;
    height: 100vh;
    max-width: 450px;
    max-height: 900px;
    margin: 0 auto;
}
.flipbook {
    /* margin-top: 30px; */
    width: 100%;
    height: 100%;
}
.flipbook .top--bar {
    height: 60px;
    background-color: #FECC33;
    box-sizing: border-box;
    margin-bottom: 50px;
}
.flipbook .logo {
    width: 44px;
    margin: 8px 0 8px 16px;
    float: left;
}
.flipbook .logo--text {
    height: 18px;
    float: left;
    margin: 20px;
}
.flipbook .logo--text.big {
    height: 25px;
}
.flipbook .start--text {
    position: absolute;
    right: 48px;
    bottom:-280px;
}
.flipbook .flip--arrow {
    position: absolute;
    right: 18px;
    bottom: -300px;
}
.flipbook .start--container {
    height: 900px;
    width: 100%;
    background-color: #FECC33;
    box-sizing: border-box;
    padding-top: 70px;
}
.flipbook .start--title {
    float: right;
    background-color: white;
    box-sizing: border-box;
    padding: 0 70px 0 70px;
    margin: 0 -70px 58px 0;
    border-radius: 40px;
    border: 4px solid black;
}
.flipbook .start--title h1 {
    padding: 12px 0;
    margin: 0;
}